import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IconEnvelope from "../../static/icons/envelope-open.svg";

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" description="You wanna join the ECO-Qube project or have any questions? So feel free to contact us and let's talk." />
    <div className="max-w-screen-md mx-auto px-4 relative z-20 mt-20">
      <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Contact</h2>
      <p className="text-2xl mt-4 text-center">You wanna join the ECO-Qube project or have any questions? So feel free to contact us and let's talk.</p>
      <div className="sm:grid sm:grid-cols-2 sm:gap-12 mt-12">
        <img src={'../max_small.jpg'} alt="" className="rounded shadow-xl" />
        <div className="mt-8 sm:mt-12 text-2xl">
          <p className="font-bold font-display">Max Schulze</p>
          <p className="text-xl">Chairman of Sustainable Digital Infrastructure Alliance</p>
          <p className="inline-flex items-center mt-8 sm:mt-12">
            <IconEnvelope />
            <a href="mailto:hallo@ecoqube.com" title="Send email" className="ml-4">hallo@ecoqube.com</a>
          </p>
        </div>
      </div>
    </div>
    
  </Layout>
)

export default ContactPage
